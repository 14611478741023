<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="login-content">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <input v-model="pseudo" type="text" placeholder="Pseudo" />
      <input v-model="mdp" type="password" placeholder="Mot de passe" />
      <button class="submit" type="submit">Login</button>
    </form>
    <p>If you do not yet have a user name, you may<button class="Signup" @click="goToSignUp">Register here</button></p>
  </div>
</template>

<script>

export default {
  data() {
    return {
      pseudo: '',
      mdp: '',
    };
  },
  methods: {
    async login() {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}:5000/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pseudo: this.pseudo,
            mdp: this.mdp,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('token', data.token);
          localStorage.setItem('user_id', data.user_id);
          this.$router.push('/quizz');
        } else {
          alert('Échec de la connexion');
        }
      } catch (error) {
        console.error('Erreur lors de la connexion', error);
      }
    },
    goToSignUp() {
      this.$router.push('/signup');
    }
  },
};
</script>


<style>
.login-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  position: relative;
}

h2{
  margin-top: -70px;
  font-size: 70px;
  color: white;
}
form{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
input{
  width: 40%;
  border: none;
  height: 30px;
  border-radius: 8px;
  margin: 10px;
}
.submit{
  z-index: 1;
  margin-top: 20px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 8px;
  background-color: #D94179;
  color: white;
  font-size: 30px;
  transition: color .2s;
  cursor: pointer;
}
.submit:hover{
  background-color: white;
  color: #D94179;
}

p{
  margin-top: 35px;
  font-family: 'Esphimere';
  font-size: 20px;
  color: white;
}
.Signup{
  border: none;
  background-color: rgba(255, 255, 255, 0);
  color: #D94179;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.Signup:hover{
  color: white;
}
</style>