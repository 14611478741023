<template>
  <div class="login-view">
    <Login />
  </div>
</template>

<script>
import Login from '@/components/Login.vue';

export default {
  components: {
    Login
  }
};
</script>

<style>

.login-view {
  margin-top: -70px;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/img/home-bg.png");
}
</style>
