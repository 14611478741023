<template>
  <div class="visualization-container">
    <Doughnut :data="chartData" :options="chartOptions"/>
  </div>
</template>

<script>
import {Doughnut} from 'vue-chartjs';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, Title} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';

// Register Chart.js components including Title and Datalabels
ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

export default {
  components: {
    Doughnut
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: []
        }]
      },
      chartOptions: {
        plugins: {
          legend: {
            position: 'top',
            labels: {
              boxWidth: 20,
              color: 'white'
            }
          },
          tooltip: {
            enabled: true
          },
          title: {
            display: true,
            text: 'Répartition des bonnes réponses pour tous les joueurs',
            font: {
              size: 20,
              family: 'sans-serif'
            },
            color: 'white'
          },
          datalabels: {
            color: '#fff',
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.forEach(data => sum += data);
              let percentage = (value * 100 / sum).toFixed(2) + "%";
              return percentage;
            },
            font: {
              weight: 'bold'
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`${process.env.VUE_APP_BASE_URL}:5000/api/stat_global`)
          .then(response => {
            this.prepareDonut(response.data);
          })
          .catch(error => {
            console.error("Error loading the data: ", error);
          });
    },
    prepareDonut(data) {
      const answerDistributions = data.answer_distribution;
      const labels = answerDistributions.map(ad => ad.resultat === 1 ? 'Réponses justes' : 'Réponses fausses');
      const dataPoints = answerDistributions.map(ad => ad.count);
      const backgroundColors = labels.map(label => label === 'Réponses justes' ? '#2d133b' : '#da427a');

      this.chartData = {
        labels: labels,
        datasets: [{
          data: dataPoints,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors
        }]
      };
    }
  }
}
</script>

<style scoped>
.visualization-container {
  background-color: rgba(255, 250, 250, 0.51);
  border-radius: 15px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0px 0px 20px 7px rgba(19, 12, 59, 0.38);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
</style>
