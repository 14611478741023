<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <nav>
    <img src="../assets/img/logo.svg">
    <div class="link">
      <router-link to="/quizz">Home</router-link>
      <router-link to="/stats">Stats</router-link>
      <button @click="logout">Déconnexion</button>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    logout() {
      // Suppression du token JWT du localStorage
      localStorage.removeItem('token');
      // Redirection vers la page de connexion
      this.$router.push('/login');
    }
  }
};
</script>


<style>
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 10px 0;
}

img {
  height: 40px;
}

.link {
  display: flex;
  width: 30%;
  justify-content: space-around;
}

button {
  background-color: #eb524f;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  height: 30px;
  cursor: pointer;
  transition-duration: .2s;
}

button:hover {
  color: #eb524f;
  background-color: white;
}
</style>