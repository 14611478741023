<template>
    <HelloWorld/>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/Home.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>

<style>
/*
.home{
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100vw;
  background-image: url("../assets/img/home-bg.png");
}*/

</style>