<template>
  <div class="signup-view">
    <span></span>
    <SignUp />
  </div>
</template>

<script>
// Importez le composant SignUp ici
import SignUp from '@/components/SignUp.vue';

export default {
  components: {
    SignUp
  }
};
</script>

<style>



</style>