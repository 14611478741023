<template>
  <div class="visualization-container">
    <Bar v-if="chartData" :data="chartData" :options="chartOptions"/>
  </div>
</template>

<script>
import {Bar} from 'vue-chartjs';
import {Chart, registerables} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';

// Register the necessary Chart.js components and plugins
Chart.register(...registerables, ChartDataLabels);

export default {
  components: {
    Bar
  },
  data() {
    return {
      chartData: null,
      chartOptions: {
        plugins: {
          legend: {
            display: false  // Hide the legend
          },
          tooltip: {
            enabled: true  // Enable tooltips
          },
          datalabels: {
            color: '#000',  // Change datalabels color to black
            anchor: 'center', // Anchor labels in the center of the bar
            align: 'center',  // Align labels in the center vertically
            font: {
              weight: 'bold'
            },
            formatter: (value, ctx) => {
              // Ensures the value is displayed only if it's large enough to fit in the bar
              return ctx.chart.isDatasetVisible(ctx.datasetIndex) ? value : '';
            }
          },
          title: {
            display: true,
            text: 'Top 3 des Quizz avec les moyennes les plus élevées', // Updated title
            font: {
              size: 20,
              family: 'sans-serif',
            },
            color: 'white',
          }
        },
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 0.5,  // Define tick intervals
              max: 1,         // Maximum value on the Y-axis
              min: 0,         // Minimum value on the Y-axis
              color: 'white'  // Change y-axis labels color to white
            }
          },
          x: {
            ticks: {
              color: 'white'  // Change x-axis labels color to white
            }
          }
        }
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`${process.env.VUE_APP_BASE_URL}:5000/api/stat_global`)
          .then(response => {
            this.prepareChartData(response.data);
          })
          .catch(error => {
            console.error("Error loading the data: ", error);
          });
    },
    prepareChartData(data) {
      if (data && data.top_10_avg_scores && data.top_10_avg_scores.length > 0) {
        const topThreeQuizzes = data.top_10_avg_scores
            .sort((a, b) => b.average_score - a.average_score)
            .slice(0, 3);

        const labels = topThreeQuizzes.map(quiz => quiz.quiz_name);
        const scores = topThreeQuizzes.map(quiz => quiz.average_score);
        const backgroundColors = ['#fff', '#fff', '#fff'];
        const borderColors = ['#000', '#000', '#000'];  // Set border color to black

        this.chartData = {
          labels: labels,
          datasets: [{
            label: 'Score Moyen',
            data: scores,
            backgroundColor: backgroundColors,
            borderColor: borderColors,  // Apply border color
            borderWidth: 3
          }]
        };
      } else {
        console.error("Les données top_10_avg_scores sont manquantes ou vides.");
      }
    }
  }
}
</script>

<style scoped>
.visualization-container {
  background-color: rgba(255, 250, 250, 0.51);
  border-radius: 15px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0px 0px 20px 7px rgba(19, 12, 59, 0.38);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
