<template>
  <div class="signup-container">
    <form @submit.prevent="signUp">
      <h3>Sign Up</h3>

      <div class="form-group">
        <label for="pseudo">Pseudo</label>
        <input id="pseudo" v-model="pseudo" type="text" placeholder="Pseudo" required>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" v-model="email" type="email" placeholder="Email" required>
      </div>
      <div class="form-group">
        <label for="mdp">Mot de passe</label>
        <input id="mdp" v-model="mdp" type="password" placeholder="Mot de passe" required>
      </div>
      <div class="form-group">
        <label for="tranche_age">Tranche d'âge</label>
        <select id="tranche_age" v-model="tranche_age">
          <option value="">Sélectionnez votre tranche d'âge</option>
          <option value="-18">-18</option>
          <option value="18-30">18-30</option>
          <option value="30-45">30-45</option>
          <option value="45+">45+</option>
        </select>
      </div>
      <div class="form-group">
        <label>Genre</label>
        <div class="radio-group">
          <input type="radio" id="homme" v-model="genre" value="Homme">
          <label for="homme">Homme</label>
          <input type="radio" id="femme" v-model="genre" value="Femme">
          <label for="femme">Femme</label>
        </div>
      </div>
      <button type="submit" class="submit-button">Inscription</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pseudo: '',
      email: '',
      mdp: '',
      tranche_age: '',
      genre: '',
    };
  },
  methods: {
    async signUp() {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}:5000/create_account`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pseudo: this.pseudo,
            mdp: this.mdp,
            email: this.email,
            tranche_age: this.tranche_age,
            genre: this.genre,
          }),
        });
        if (response.ok) {
          alert('Inscription réussie');
          this.$router.push('/quizz');
        } else {
          const data = await response.json();
          alert(`Erreur d'inscription: ${data.message}`);
        }
      } catch (error) {
        console.error("Erreur lors de l'inscription", error);
        alert("Une erreur s'est produite lors de l'inscription.");
      }
    },
  },
};
</script>

<style scoped>
.signup-container {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(249, 249, 249, 0.85);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h3 {
  text-align: center;
  margin-bottom: 20px; /* Adjust this as necessary */
  color: #000;
  font-size: xx-large;
}

.form-group {
  width: 80vw;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  display: block;
  width: 80%;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
}


input[type="text"],
input[type="email"],
input[type="password"],
select {
  width: 80%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.radio-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.radio-group input[type="radio"] {
  margin-right: 5px;
}

.submit-button {
  border: none;
  background-color: #D94179;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;
}

.submit-button:hover {
  background-color: white;
  color: #D94179;}
</style>
