<template>
  <div class="average-score-container">
    <h3 class="title">Score moyen aux quizz pour les participants</h3>
    <div v-if="averageScore !== null" class="average-score">
      {{ averageScore.toFixed(2) }}
    </div>
    <div v-else>
      Chargement ou aucune donnée disponible...
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    averageScore: null
  }
},
mounted() {
  this.fetchData();
},
methods: {
  fetchData() {
    axios.get(`${process.env.VUE_APP_BASE_URL}:5000/api/stat_global`)
      .then(response => {
        this.prepareAverageScore(response.data);
      })
      .catch(error => {
        console.error("Erreur lors du chargement des données: ", error);
      });
  },
  prepareAverageScore(data) {
    if (data && data.avg_score_per_quiz) {
      let totalScore = data.avg_score_per_quiz.reduce((acc, cur) => acc + parseFloat(cur.average_score), 0);
      let averageScore = totalScore / data.avg_score_per_quiz.length;
      this.averageScore = averageScore;
    } else {
      console.error("Data is missing or the format is incorrect.");
    }
  }
}
}
</script>

<style scoped>
.average-score-container {
  background-color: rgba(255, 250, 250, 0.51);
border-radius: 15px; /* Consistent rounded corners */
padding: 20px; /* Consistent padding */
  box-shadow: 0px 0px 20px 7px rgba(19, 12, 59, 0.38);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.title{
  color: white;
  font-family: sans-serif;
  font-size: 20px;
}

.average-score {
font-size: 100px; /* Increased font size for better visibility */
font-weight: bold;
color: white; /* Vibrant pink for numeric values */
margin-top: 10px; /* Spacing from the title for better layout */
}
</style>
