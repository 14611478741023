import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from "@/views/LoginView.vue";
import SignUpView from "@/views/SignUpView.vue";
import StatHub from "@/components/StatHub.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUpView
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatHub
  },
  {
    path: '/quizz',
    name: 'quizz',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuizzView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/questionnaire/:questionnaireId',
    name: 'questionnaireDetails',
    component: () => import('../views/QuestionnaireDetailsView.vue')
  },
  {
    path: '/voicequizz',
    name: 'VoiceQuizz',
    component: () => import(/* webpackChunkName: "voicequizz" */ '../views/VoiceQuizzView.vue'),
    meta: { requiresAuth: true }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  // Vérifie si la route nécessite une authentification
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Cette route nécessite une authentification, vérifie si l'utilisateur est connecté
    // Si non, redirige vers la page de connexion
    if (!localStorage.getItem('token')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath } // Stocke le chemin pour le rediriger après la connexion
      })
    } else {
      next() // sinon, continue vers la route
    }
  } else {
    next() // Assure-toi toujours d'appeler next() !
  }
})

export default router
