<template>
    <div class="visualization-container">
      <Bar v-if="chartData" :data="chartData" :options="chartOptions" />
    </div>
  </template>

  <script>
  import { Bar } from 'vue-chartjs';
  import { Chart, registerables } from 'chart.js';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import axios from 'axios';

  // Registering components of Chart.js and datalabels plugin
  Chart.register(...registerables, ChartDataLabels);

  export default {
    components: {
      Bar
    },
    data() {
      return {
        chartData: null,
        chartOptions: {
          plugins: {
            legend: {
              display: false  // Do not display the legend.
            },
            tooltip: {
              enabled: true  // Enable tooltips for hover information.
            },
            datalabels: {
              color: '#fff',  // White color for the text
              anchor: 'center',  // Center the label inside the bar
              align: 'center',  // Align vertically in the center
              font: {
                weight: 'bold',  // Bold font weight
              }
            },
            title: {
              display: true,
              text: 'Top 10 des Quizz avec la meilleur moyenne', // Updated title of the chart
              font: {
                size: 20,
                family: 'sans-serif',
              },
              color: 'white',
            }
          },
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 0.5,  // Adjusted for finer granularity
                max: 1,  // Set the maximum value of the y-axis to 1.
                min: 0,  // Set the minimum value of the y-axis to 0.
                color: 'white'  // Change y-axis labels color to white

              }
            },
            x: {
              ticks: {
                color: 'white'  // Change x-axis labels color to white
              }
            }
          }
        }
      }
    },
    mounted() {
      this.fetchData();  // Fetch data when the component is mounted.
    },
    methods: {
      fetchData() {
        const userId = localStorage.getItem('user_id');

        axios.get(`${process.env.VUE_APP_BASE_URL}:5000/api/stat_user/${userId}`)
          .then(response => {
            this.prepareChartData(response.data);
          })
          .catch(error => {
            console.error("Error loading the data: ", error);
          });
      },
      prepareChartData(data) {
        if (data && data.top_10_avg_scores && data.top_10_avg_scores.length > 0) {
          const labels = data.top_10_avg_scores.map(quiz => quiz.quiz_name);
          const scores = data.top_10_avg_scores.map(quiz => parseFloat(quiz.average_score));

          this.chartData = {
            labels: labels,
            datasets: [{
              label: 'Average Scores',
              data: scores,
              backgroundColor: [
                '#2d133b'
              ],
              borderColor: [
                '#2d133b'
              ],
              borderWidth: 1
            }]
          };
        } else {
          console.error("Data is missing or the format is incorrect.");
        }
      }
    }
  }
  </script>

  <style scoped>
  .visualization-container {
    background-color: rgba(255, 250, 250, 0.51);
    border-radius: 15px; /* Rounded corners */
    padding: 20px;
    box-shadow: 0px 0px 20px 7px rgba(19, 12, 59, 0.38);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
