<template>
  <div class="visualization-container">
    <Doughnut :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';

// Register Chart.js components including Title and Datalabels
ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

export default {
  name: 'AnswerDistributionDoughnut',
  props: {
    ageRange: {
      type: String,
      required: true
    }
  },
  components: {
    Doughnut
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: []
        }]
      },
      chartOptions: {
        plugins: {
          legend: {
            display: false  // Do not display the legend.
          },
          tooltip: {
            enabled: true  // Enable tooltips for hover information.
          },
          title: {
            display: true,
            text: 'Répartition des bonnes et mauvaises réponses',
            font: {
              size: 20,
              family: 'sans-serif',
            },
            color: 'white',
          },
          datalabels: {
            color: '#fff',
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.forEach(data => sum += data);
              let percentage = (value * 100 / sum).toFixed(2) + "%";
              return percentage;
            },
            font: {
              weight: 'bold'
            }
          }
        },
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 0.5,  // Set each step on the y-axis to be 0.5.
              max: 1,  // Set the maximum value of the y-axis to 1.
              min: 0,  // Set the minimum value of the y-axis to 0.
              color: 'white'  // Change y-axis labels color to white
            }
          },
          x: {
            ticks: {
              color: 'white'  // Change x-axis labels color to white
            }
          }
        },
        maintainAspectRatio: false
      }
    }
  },

  watch: {
    ageRange: 'fetchData'  // Watch for changes in the ageRange prop and call fetchData when it changes
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`${process.env.VUE_APP_BASE_URL}:5000/api/stat_tranche_age/${this.ageRange}`)
        .then(response => {
          this.prepareDonut(response.data);
        })
        .catch(error => {
          console.error("Error loading the data: ", error);
        });
    },
    prepareDonut(data) {
      const answerDistributions = data.answer_distribution_age;
      const labels = answerDistributions.map(ad => ad.resultat === 1 ? 'Réponses justes' : 'Réponses fausses');
      const dataPoints = answerDistributions.map(ad => ad.total);
      const backgroundColors = labels.map(label => label === 'Réponses justes' ? '#2d133b' : '#da427a');

      this.chartData = {
        labels: labels,
        datasets: [{
          data: dataPoints,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors
        }]
      };
    }
  }
}
</script>

<style scoped>

.visualization-container {
  background-color: rgba(255, 250, 250, 0.51);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 0px 20px 7px rgba(19, 12, 59, 0.38);
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
