<template>
  <div>
    <div class="filter-buttons">
      <div class="selecteur-age">
        <button class="stat-btn" @click="setFilter('global')" :class="{active: currentFilter === 'global'}">Global</button>
        <button class="stat-btn" @click="setFilter('player')" :class="{active: currentFilter === 'player'}">Joueur</button>
      </div>
      <div class="selecteur-age" v-if="currentFilter !== 'player'">
        <button class="stat-btn" @click="setAgeFilter('18')">-18</button>
        <button class="stat-btn" @click="setAgeFilter('18-30')">18-30</button>
        <button class="stat-btn" @click="setAgeFilter('30-45')">30-45</button>
        <button class="stat-btn" @click="setAgeFilter('45+')">45+</button>
      </div>
    </div>
    <div :class="['grid-container', currentFilter]">
      <div class="grid-item" v-if="currentFilter === 'global'">
        <DataVisualization class="data-viz" />
        <AverageScoreMetric class="average-score-metric" />
        <TopTenQuizzes class="top-ten-quizzes" />
        <BottomThreeQuizzes class="bottom-three-quizzes" />
        <GlobalBestUsers class="best-users" />
      </div>
      <div class="grid-item" v-if="currentFilter === 'player'">
        <PlayerScore class="player-score" :participantId="1" />
        <PlayerTotalAnswer class="player-total-answer" />
        <AnswerDistributionDoughnut class="answer-distribution-doughnut" />
        <QuizAverageScoreChart class="quiz-average-score-chart" />
      </div>
      <div class="grid-item" v-if="currentFilter === 'age'">
        <AgeAnswerDistribution class="age-answer-distribution" :ageRange="currentAgeFilter" />
        <AgeMetricTotalAnswer class="age-total-answer" :ageRange="currentAgeFilter" />
        <AgeTopTen class="age-topten" :ageRange="currentAgeFilter" />
        <AgeBottomThree class="age-bottom-three" :ageRange="currentAgeFilter" />
      </div>
    </div>
   <!-- <button @click="downloadPDF" class="download-btn">Télécharger PDF</button> -->
  </div>
</template>

<script>
import DataVisualization from './stat/DataVisualization.vue'
import TopTenQuizzes from './stat/TopTen.vue'
import BottomThreeQuizzes from './stat/BottomThreeQuizzes.vue'
import AverageScoreMetric from './stat/AverageScoreMetric.vue'
import PlayerScore from './stat/PlayerScore.vue'
import AnswerDistributionDoughnut from './stat/AnswerDistributionDoughnut.vue'
import QuizAverageScoreChart from './stat/PlayerQuizzAverageScore.vue'
import PlayerTotalAnswer from './stat/PlayerTotalAnswer.vue'
import AgeAnswerDistribution from './stat/AgeAnswerDistribution.vue';
import AgeTopTen from "@/components/stat/AgeTopTen.vue";
import AgeBottomThree from "@/components/stat/AgeBottomThree.vue";
import AgeMetricTotalAnswer from "@/components/stat/AgeMetricTotalAnswer.vue";
import GlobalBestUsers from "@/components/stat/GlobalBestUsers.vue";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default {
  name: 'StatHub',
  components: {
    DataVisualization,
    TopTenQuizzes,
    BottomThreeQuizzes,
    AverageScoreMetric,
    PlayerScore,
    AnswerDistributionDoughnut,
    QuizAverageScoreChart,
    PlayerTotalAnswer,
    AgeAnswerDistribution,
    AgeBottomThree,
    AgeMetricTotalAnswer,
    GlobalBestUsers,
    AgeTopTen
  },
  data() {
    return {
      currentFilter: 'global',
      currentAgeFilter: null
    };
  },
  methods: {
    setFilter(filter) {
      this.currentFilter = filter;
      this.currentAgeFilter = null;
    },
    setAgeFilter(ageRange) {
      this.currentFilter = 'age';
      this.currentAgeFilter = ageRange;
    },
    downloadPDF() {
      html2canvas(document.querySelector("#app"), {
        useCORS: true, // Cette option permet de charger les images avec des CORS
        scale: 2, // Augmente la résolution du canvas
        onclone: (document) => {
          document.getElementById("app").style.visibility = "visible"; // S'assure que tout est visible
        }
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [canvas.width, canvas.height]
        });
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
        pdf.save("download.pdf");
      }).catch(err => {
        console.error("Failed to load the page canvas.", err);
      });
    }
  }
}
</script>

<style>
#app {
  text-align: center;
  width: 100%;
}

.filter-buttons .active {
  background-color: white;
  color: #D94179;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 10%; /* Added margin to the container */
}

.grid-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%; /* Ensure items take full width of the container */
}

.grid-item > * {
  flex: 1 1 calc(50% - 20px); /* Adjust the width of each item */
  box-sizing: border-box;
}

.download-btn {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.age-buttons {
  margin-top: 20px;
}

.filter-buttons {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-buttons button {
  margin: 5px 0;
  padding: 10px 20px;
  cursor: pointer;
}

.filter-buttons .selecteur-age {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.filter-buttons .selecteur-age button {
  margin: 0 5px; /* Add space between buttons */
}

.stat-btn {
  border: none;
  background-color: #D94179;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;
}

.stat-btn:hover {
  background-color: white;
  color: #D94179;
}
</style>