<template>
  <div class="visualization-container">
    <Bar v-if="chartData" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';

// Registering components of Chart.js and datalabels plugin
Chart.register(...registerables, ChartDataLabels);

export default {
  components: {
    Bar
  },
  data() {
    return {
      chartData: null,
      chartOptions: {
        plugins: {
          legend: {
            display: false  // Do not display the legend.
          },
          tooltip: {
            enabled: true  // Enable tooltips for hover information.
          },
          datalabels: {
            color: '#fff',  // White color for the text
            anchor: 'center',  // Center the label inside the bar
            align: 'center',  // Align vertically in the center
            font: {
              weight: 'bold',  // Bold font weight
            }
          },
          title: {
            display: true,
            text: 'Top 3 des Quizz avec les moyennes les plus faibles', // Title of the chart
            font: {
              size: 20,
              family: 'sans-serif',
            },
            color: 'white',
          }
        },
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 0.5,  // Set each step on the y-axis to be 0.5.
              max: 1,  // Set the maximum value of the y-axis to 1.
              min: 0,  // Set the minimum value of the y-axis to 0.
              color: 'white'  // Change y-axis labels color to white
            }
          },
          x: {
            ticks: {
              color: 'white'  // Change x-axis labels color to white
            }
          }
        }
      }
    }
  },
  mounted() {
    this.fetchData();  // Fetch data when the component is mounted.
  },
  methods: {
    fetchData() {
      axios.get(`${process.env.VUE_APP_BASE_URL}:5000/api/stat_global`)
        .then(response => {
          this.prepareChartData(response.data);
        })
        .catch(error => {
          console.error("Error loading the data: ", error);
        });
    },
    prepareChartData(data) {
      if (data && data.top_10_avg_scores && data.top_10_avg_scores.length > 0) {
        const bottomThreeQuizzes = data.top_10_avg_scores
          .sort((a, b) => parseFloat(a.average_score) - parseFloat(b.average_score))
          .slice(0, 3);

        const labels = bottomThreeQuizzes.map(quiz => quiz.quiz_name);
        const scores = bottomThreeQuizzes.map(quiz => parseFloat(quiz.average_score));

        this.chartData = {
          labels: labels,
          datasets: [{
            label: 'Lowest Average Scores',
            data: scores,
            backgroundColor: [
              '#000', // Corrected color descriptions
              '#000',
              '#000'
            ],
            borderColor: [
              '#fff',
              '#fff',
              '#fff'
            ],
            borderWidth: 3
          }]
        };
      } else {
        console.error("Data is missing or the format is incorrect.");
      }
    }
  }
}
</script>

<style scoped>
.visualization-container {
  background-color: rgba(255, 250, 250, 0.51);
  border-radius: 15px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0px 0px 20px 7px rgba(19, 12, 59, 0.38);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
