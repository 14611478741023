<template>
  <div class="hello">
    <h1>Welcome to</h1>
    <img class="logo" src="../assets/img/logo.svg" alt="logo luna quizz">
    <button @click="start">Start</button>
    <img class="hero-banner" src="../assets/img/hero.png" alt="hero banner">
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  methods: {
    start() {
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
.hello {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("../assets/img/home-bg.png");
  width: 100vw;
  height: 100vh;
  margin-top: -70px;
}

.hero-banner {
  position: absolute;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

h1 {
  z-index: 1;
  font-family: 'Esphimere';
  color: white;
}

.logo {
  z-index: 1;
  height: 120px;
}

button {
  z-index: 1;
  margin-top: 20px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 8px;
  background-color: #D94179;
  color: white;
  font-size: 30px;
  transition: color .2s;
}

button:hover {
  background-color: white;
  color: #D94179;
}
</style>
