<template>
    <div class="total-answers-container">
      <h3 class="title">Total des réponses aux quiz</h3>
      <div v-if="totalAnswers !== null" class="total-answers">
        {{ totalAnswers }}
      </div>
      <div v-else>
        Chargement ou données non disponibles...
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';

  export default {
    data() {
      return {
        totalAnswers: null
      };
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        const userId = localStorage.getItem('user_id');

        axios.get(`${process.env.VUE_APP_BASE_URL}:5000/api/stat_user/${userId}`)
          .then(response => {
            this.totalAnswers = response.data.total_answers;
          })
          .catch(error => {
            console.error("Erreur lors du chargement des données: ", error);
          });
      }
    }
  };
  </script>

  <style scoped>
  .total-answers-container {
    background-color: rgba(255, 250, 250, 0.51);
    border-radius: 15px; /* Coins arrondis constants */
    padding: 20px; /* Rembourrage constant */
    box-shadow: 0px 0px 20px 7px rgba(19, 12, 59, 0.38);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title{
    color: white;
    font-family: sans-serif;
    font-size: 20px;
  }

  .total-answers {
    font-size: 100px; /* Taille de police augmentée pour la visibilité */
    font-weight: bold;
    color: white; /* Rose vibrant pour les valeurs numériques */
    margin-top: 10px; /* Espacement du titre pour une meilleure mise en page */
  }
  </style>
