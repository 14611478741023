<template>
  <div class="metric-container">
    <h3 class="title">Total des réponses</h3>
    <div v-if="totalAnswers !== null" class="metric">
      {{ totalAnswers }}
    </div>
    <div v-else>
      Chargement ou aucune donnée disponible...
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TotalAnswersMetric',
  props: {
    ageRange: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      totalAnswers: null
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    ageRange: 'fetchData'  // Watch for changes in the ageRange prop and call fetchData when it changes
  },
  methods: {
    fetchData() {
      axios.get(`${process.env.VUE_APP_BASE_URL}:5000/api/stat_tranche_age/${this.ageRange}`)
        .then(response => {
          this.processData(response.data);
        })
        .catch(error => {
          console.error("Erreur lors du chargement des données: ", error);
        });
    },
    processData(data) {
      if (data.total_answers_age !== undefined) {
        this.totalAnswers = data.total_answers_age;
      } else {
        console.error("Aucune donnée trouvée pour 'total_answers_age'");
      }
    }
  }
}
</script>

<style scoped>
.metric-container {
  background-color: rgba(255, 250, 250, 0.51);
  border-radius: 15px; /* Consistent rounded corners */
  padding: 20px; /* Consistent padding */
  box-shadow: 0px 0px 20px 7px rgba(19, 12, 59, 0.38);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto; /* Centering in the parent element */
}

.title{
  color: white;
  font-family: sans-serif;
  font-size: 20px;
}

.metric {
  font-size: 100px; /* Increased font size for better visibility */
  font-weight: bold;
  color: white; /* Vibrant pink for numeric values */
  margin-top: 10px; /* Spacing from the title for better layout */
}
</style>
